.support-card {
  max-width: 500px;
  margin: 80px auto;
  background-color: white;
  border-radius: 25px;
  padding: 30px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.support-card .input-group input {
  height: 50px;
}

.support-card .input-group input, .input-group textarea {
  width: 100%;
}

.support-header { 
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center;
  margin-bottom: 20px;
}

.input-group input {
  text-align: start;
}

.support-icon {
  width: 50px;
  margin-bottom: 10px;
}

.support-header h2 {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

.support-header p {
  font-size: 14px;
  color: #666;
}

.support-form {
  display: flex;
  flex-direction: column;
}

.input-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.input-group input,
.input-group textarea {
  flex: 1;
  padding: 15px;
  border-radius: 32px;
  border: 1px solid #ccc;
  font-size: 14px;
  outline: none;
  background-color: #ffffff;
}

.input-group textarea {
  height: 100px;
}

.submit-button {
  width: 100%;
  padding: 15px;
  border-radius: 25px;
  border: none;
  background-color: rgb(132, 183, 78);
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: rgb(85, 120, 48);
}

@media (max-width: 768px) {
  .input-group {
    flex-direction: column;
  }
}
