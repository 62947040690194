.footer-container {
  background-color: #fff; /* White background */
}

.footer-container> div{
  color: #333; /* Dark text color */
  padding: 10px 20px 30px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: auto;
}

.footer-content {
  display: flex;
  align-items: center;
} 

.footer-links ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.footer-links li {
  margin-right: 20px;
}

.footer-links a {
  color: #333; /* Dark text color */
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s ease;
}

.copyright p {
  margin: 0;
  max-width: 1200px;
  margin: auto;
}

@media (max-width: 768px) {
  .footer-links ul {
    flex-direction: column;
  }
}