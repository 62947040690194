.grocery-list {
    padding-bottom: 4rem;
}

.grocery-list li {
    box-shadow: none !important;
    /* cursor: pointer; */
    background-color: lightgray !important;
    border-radius: 42px;
    padding: 0 8px;
    padding-left: 40px;
    gap: 1rem;
    min-height: 50px;
}

.grocery-list li .measurement {
    background: white;
    padding: 8px;
    border-radius: 42px;
    min-width: 100px;
    height: 40px;
    text-align: center;
}

.question {
    padding: 4rem;
    border-radius: 70px;
    background: #fff;
    box-shadow: 0px 3px 10px #7D7D7D38;
}

.question h3 {
    text-align: start;
    margin-bottom: 20px;
}

.meal-preferences {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}

.question-btn {
    text-align: center;
    margin-top: 20px;
}

.question-btn .ok {
    background: #84B74E ;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}


.recipe-selection {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}


.handle-recipes {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.navigation-buttons {
    display: flex;
    align-items: center;
}

.navigation-buttons button {
    background: none;
    border: none;
    cursor: pointer;
}

.navigation-buttons button svg {
    width: 24px;
    height: 24px;
}

.navigation-buttons button:disabled {
    cursor: not-allowed;
}

.navigation-buttons span {
    margin: 0 10px;
}

.recipes {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.diet-options-container button {
    padding: 1em 2.25em !important;
    background: #DBDBDB;
    border-radius: 32px;
}

.course {
    width: 100%;
    margin-bottom: 40px;
}


.category-header {
    display: flex;
    justify-content: space-between;
}

.category-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    background-color: #FFE599;
    padding: 10px;
    border-radius: 8px;
    max-width: 250px;
    display: flex;
    position: relative;
    min-width: 200px;
    justify-content: center;
}

.category-header .line {
    position: relative;
    width: 100%;
    display: flex;
}

.category-header .line::after {
    content: "";
    width: 90%;
    height: 3px;
    background-color: hsl(0, 0%, 91%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.recipe-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    justify-items: center;
}

.recipe-list .recipe-card {
    border-radius: 40px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    padding: 0 !important;
}

.breakfast .category-title,
.breakfast .recipe-list .recipe-card {
    background-color: #FFECB3 !important;
    ;
}

.salads .category-title,
.salads .recipe-list .recipe-card {
    background-color: #C8E6C9 !important;
    ;
}

.main .recipe-list .recipe-card,
.main .category-title {
    background-color: #FFD7A8 !important;
}

.snacks .recipe-list .recipe-card,
.snacks .category-title {
    background-color: #D4E6F1 !important;
}

.sweets .recipe-list .recipe-card,
.sweets .category-title {
    background-color: #FFCCB6  !important;
}

.recipe-list .recipe-card:hover {
    transform: translateY(-5px);
}

.recipe-list .recipe-card img {
    width: 100%;
    height: 350px;
    object-fit: cover;
}

.recipe-list .recipe-card .recipe-info {
    padding: 10px;
    text-align: center;
}

.recipe-list .recipe-card .recipe-info h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}

.recipe-list .recipe-card .recipe-info .details {
    font-size: 14px;
    color: #666;
}

.next-step {
    margin-top: 20px;
}

.next-step a {
    display: inline-block;
    padding: 10px 20px;
    background-color: #ff5722;
    color: white;
    text-decoration: none;
    border-radius: 8px;
    transition: background-color 0.3s;
}

.next-step a:hover {
    background-color: #e64a19;
}

@media (max-width: 996px) {
    .question {
        padding: 2rem 1.5rem;
    }

    .recipe-list {
        flex-direction: row;
        justify-content: center;
    }

    .meal-item label {
        padding-left: 0 !important;
    }
     
    .course .recipe-list .recipe-card {
        max-width: 46%;
    }

    .recipe-card h3 {
        font-size: 1rem;
        margin: 0.75rem;
    }

    .recipe-list .recipe-card img {
        height: 200px;
    }

    .recipe-card .content {
        min-height: 0;
    }

    .grocery-list li {
        padding-left: 20px;
        padding-top: 4px;
        padding-bottom: 4px;
    }
}