body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
}

/* Hero Section */
.hero {
    background: #84B74E;
}

.home h2 {
    font-size: 2.25rem;
}

.hero .container {
    color: white;
    padding: 100px 0;
    text-align: center;
    max-width: 996px;
    display: flex;
    flex-direction: row;
    align-items: start;
}

.hero h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
    font-weight: 600 !important;
}

.hero p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    color: white;
}

.hero .primary,
.promo-section .primary {
    background-color: white !important;
    padding: 10px 20px;
    color: #84B74E !important;
    border-radius: 5px;
    text-decoration: none;
    max-width: 250px;
    border-radius: 32px;
    margin: auto;
}

.promo-section .primary {
    max-width: 200px;
}

/* Promo Section */
.promo-section {
    padding: 50px 20px;
    background: #84B74E !important;
    color: white;
}

.promo-section .container {
    max-width: 1100px;
}

.promo-section h2 {
    max-width: 45%;
    font-size: 2.25rem;
    margin-bottom: 1rem;
    text-align: start;
}

.promo-section .container div{
    margin-top: 1rem;
}

.promo-section .primary {
    padding: 10px 20px;
    color: white;
    margin: 1.35rem 0;
}

/* Steps Section */
.steps-container h2 {
    margin-bottom: 3rem;
}

.steps-container {
    padding: 50px 20px;
    background-color: #ffffff;
}

h2 {
    font-size: 2.5rem;
    text-align: center;
}

.steps-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    gap: 1rem;
    text-align: center;
}

.step-number {
    width: 50px;
    height: 50px;
    /* background-color: #c5161e; */
    background-color: #84B74E;
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    color: #FFF;
    font-family: Outfit;
    font-size: 24px;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
}

.step h3 {
    color: #080708;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
}

.step-title {
    font-size: 1.5rem;
    color: #333;
    margin: 1rem 0;
}

.step .step-description {
    color: #4F5D75;
    margin: 0;
    text-align: center;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 144%;
    margin: auto;
    padding: 0 2rem;
}

.steps-container {
    text-align: center;
    margin: 5rem auto;
}

/* What You'll Receive Section */
.receive-section {
    padding: 50px 20px;
    background-color: #faf9f8;
    text-align: center;
}

.receive-section .subtitle {
    margin-bottom: 3rem;
}

.receive-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.receive-item {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.receive-item img {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
}

.receive-item h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.receive-item p {
    font-size: 1rem;
    color: #4f4f4f;
    text-align: start;
}

/* FAQ Section */
.faq {
    padding: 50px 20px;
    text-align: center;
}

.faq .row-content {
    /* padding: 0 4.5rem; */
    text-align: start;
}

.faq-row-wrapper {
    max-width: 996px;
    margin: auto;
}

.faq-row-wrapper .faq-row {
    display: flex;
    align-items: start;
}

.row-title {
    width: 100%;
    text-align: start;
    gap: 3rem;
    justify-content: start !important;
    align-items: start !important;
    padding: 1rem 0 !important;
}

.faq-row .row-title .icon-wrapper {
    top: 24px;
}

.faq-row .row-title .icon-wrapper::before {
    content: "";
    width: 20px;
    height: 20px;
    transition: all 0.3 ease;
    background: no-repeat url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21" fill="none"><path d="M19 11.8571L17.3884 10.2857L10.6663 16.7551L10.6663 0L8.3337 0L8.3337 16.7551L1.63281 10.2857L0 11.8571L9.5 21L19 11.8571Z" fill="%2315141A"/></svg>') center/cover;
}

/* 
.faq-row .row-title.closed .icon-wrapper::before {
    transform: translate(-90deg);
} */

/* .faq-row .row-title.expanded .icon-wrapper::before {
    transform: translate(0);
} */

.faq-row-wrapper .faq-row:first-of-type .row-title::before {
    content: "01";
}

.faq-row-wrapper .faq-row:nth-of-type(2) .row-title::before {
    content: "02";
}

.faq-row-wrapper .faq-row:nth-of-type(3) .row-title::before {
    content: "03";
}

.faq-row-wrapper .faq-row:nth-of-type(4) .row-title::before {
    content: "04";
}

.faq-row-wrapper .faq-row:nth-of-type(5) .row-title::before {
    content: "05";
}

.row-content-text {
    text-align: start;
    margin-left: 4rem;
    padding-right: 2rem !important;
}

.faq-item {
    text-align: left;
    margin-bottom: 30px;
}

.faq-item h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.faq-item p {
    font-size: 1.1em;
}

.usps {
    background: #faf9f8;
    padding: 3rem !important;
}

.usps h2 {
    text-align: center;
    font-weight: 700;
}

.usp {
    display: flex;
    gap: 8px;
    margin: 3rem 2rem;
    max-width: 350px;
}

.usp>span {
    color: rgba(21, 20, 26, 0.20);
    text-align: right;
    font-family: Recoleta;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%
}

.usp>div h4 {
    color:#15141A;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    margin: 0;
}

.usp>div p {
    color: rgba(8, 7, 8, 0.60);
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 144%; 
    margin: 0;
}

.usps>div {
    display: flex;
    margin: auto;
    max-width: 1400px;
    gap: 2rem;
    align-items: center;
    justify-content: center;
}

.usps .img {
    width: 290px;
    min-width: 290px;
    height: 600px;
    border-radius: 210px;
    background: white;
    border: 10px solid rgba(132, 183, 78, 0.3);
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.usps .img img {
    width: 400px;
    height: 100%;
}

/* Signup Section */
.home .sign-up {
    color: #000;
    padding: 50px 3rem;
    text-align: start;
    background: #faf9f8;
    max-width: 1200px;
    margin: auto;
    text-align: start;
    border-radius: 24px;
    margin-bottom: 1rem;
}

.home .sign-up h2 {
    text-align: start;
    margin-bottom: 1rem;
}

.home .sign-up p {
    margin-left: 0;
    max-width: 90%;
}

.home .sign-up button {
    margin-left: 0;
}

.sign-up p {
    font-size: 1.2em;
    margin-bottom: 30px;
}

.sign-up a {
    margin: 0 !important;
}

.swiper-pagination-bullet-active {
    background-color: #000;
}

.swiper-pagination {
    position: relative;
    padding-top: 25px;
}

/* Scroll Text */
.scroll-text-container {
    background-color: #f7f7f7;
    padding: 10px 0;
    color: #333;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}

.scroll-text-container marquee {
    font-size: 1.2rem;
    white-space: nowrap;
}

@media (max-width: 996px) {
    .usps .img {
        width: 200px;
        min-width: 230px;
        height: 400px; 
    }

    .usps>div {
        gap: 0;
        text-align: center;
        flex-direction: column;
    }

    .hero .container>div a.primary, .hero .container>div button.primary {
        margin: auto;
    }
    
    .usp {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
    }

    .week-recipes-images {
        display: none !important;
    }

    .hero .container {
        padding: 50px 20px;
    }
    
    .hero .container>div {
        width: 400px;
        margin: 1rem 0 1rem 0;
    }

    .usp-container .img {
        display: none;
    }

    .hero .container>div.text {
        max-width: 700px;
        width: 100%;
    }

    .hero .onions {
        width: 80px;
        height: auto;
    }

    .hero .radis {
        width: 90px;
        height: auto;
        right: 6%;
    }

    .hero .tomato {
        width: 125px;
        height: auto;
    }

    .hero .container {
        flex-direction: column;
        align-items: center;
    }

    .week-example .meal-plan>div {
        flex-direction: column-reverse;
    }

    .promo-section .container {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }

    .promo-section h2 {
        max-width: none;
        margin-bottom: 0;
    }
}


/* Responsive Styles */
@media (max-width: 996px) {

    .steps-grid,
    .receive-grid {
        grid-template-columns: 1fr;
    }

    .hero h1 {
        font-size: 2rem;
    }

    .hero p {
        font-size: 1.2rem;
    }
}