/* Style for the entire recipe list container */
.recipe-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

/* Style for each recipe item */
.recipe-item {
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    background-color: #fdfcfc;
    width: calc(33.33% - 20px);
    margin-bottom: 20px;
    box-sizing: border-box;
    transition: box-shadow 0.3s;
}

.recipe-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Style for recipe links */
.recipe-link {
    text-decoration: none;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

/* Style for recipe name */
.recipe-name {
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0;
    text-align: center;
}

/* Style for recipe description */
.recipe-description {
    color: #666;
    margin-bottom: 10px;
    text-align: center;
}

/* Style for recipe image */
.recipe-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 10px;
}

/* Style for nutritional info */
.nutritional-info {
    margin-top: 10px;
    padding: 0 20px;
}

/* Style for nutritional details */
.nutritional-details {
    list-style: none;
    padding: 0;
}

.nutritional-details li {
    margin-bottom: 5px;
}

/* Style for food preferences */
.food-preferences {
    margin-top: 10px;
    padding: 50 20px;
}

.food-preferences form {
    padding: 50px 0;
}

.food-preferences form .submit {
    padding-top: 50px;
}
 
 
/* Style for preference details */
.preference-details {
    list-style: none;
    padding: 0;
}

.preference-details li {
    margin-bottom: 5px;
}

/* Style for ingredient list */
.ingredient-list {
    margin-top: 10px;
    padding: 0 20px;
}

/* Style for ingredient details */
.ingredient-details {
    list-style: none;
}

.ingredient-details li {
    margin-bottom: 5px;
}

.recipe-list ul {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}