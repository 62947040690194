.my-account {
    display: flex;
    justify-content: center;
}

.account-info-card {
    background-color: #fff;
    padding: 3rem 2rem;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 500px;
    width: 100%;
}

.account-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

h2 {
    font-size: 24px;
    margin-bottom: 0.5rem;
}

.subtext {
    font-size: 16px;
    color: #777;
    margin-bottom: 2rem;
}

.account-details {
    margin-bottom: 2rem;
}

.detail-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0.75rem 1rem;
    border-radius: 10px;
}

.label {
    font-weight: bold;
    flex-shrink: 0;
}

.value {
    flex-grow: 1;
    text-align: right;
    color: #aaa;
}

.account-icon svg {
    display: block;
    margin: 0 auto;
    width: 80px;
    height: 80px;
}

.delete-my-account {
    background-color: #FB4D53;
    color: white;
    border: none;
    border-radius: 32px;
    padding: 0.75rem 6rem;
    min-width: 200px;
    cursor: pointer;
    font-weight: bold;
    margin-top: 1rem;
    transition: all 0.4s ease;
}

.delete-my-account:hover {
    background-color: #ff0000;
}

.confirmation-dialog {
    z-index: 100;
    background: white;
    position: fixed;
    top: 8rem;
    left: 50%;
    transform: translate(-50%);
    max-width: 500px;
    min-width: 300px;
    overflow-y: scroll;
    padding: 1.5rem;
    z-index: 15;
    border-radius: 16px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    text-align: center;
}

.confirmation-dialog h4 {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #1a1d1f;
    margin-bottom: 1rem;
}

.confirmation-dialog p {
    font-size: 16px;
    color: #555;
    margin-bottom: 2rem;
}

.confirmation-dialog .delete {
    background: #ff0000;
    color: white;
    padding: 0.75rem 1.5rem;
    border-radius: 12px;
    font-weight: 500;
    margin-right: 8px;
    border: none;
    cursor: pointer;
}

.confirmation-dialog .cancel {
    padding: 0.75rem 1.5rem;
    border-radius: 12px;
    font-weight: 500;
    background-color: #e1e1e1;
    color: #555;
    border: none;
    cursor: pointer;
}
