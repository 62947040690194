.meal-plans {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
}

.meal-plans .recipe-card {
  width: auto;
  max-width: 265px;
}

.meal-plans .recipe-card {
  background: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.meal-plans .meal-plans-title {
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif !important;
  color: #2B3231;
  font-size: 24px;
  font-weight: 600 !important;
  margin-top: 1.5rem;
}

.meal-plans .recipe-card h3 {
  font-size: 18px;
}

.week-section {
  margin-bottom: 40px;
  max-width: 1100px;
  margin: 1rem auto;
}

.week-section .download-pdf-link {
  background-color: #2B3231;
  border-radius: 32px;
  color: #fff;
  padding: 0.5em 1em;
}

.week-header {
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
}

.week-recipes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.week-recipes .img-container {
  width: 100%;
  height: 265px;
  max-width: 265px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 65px;
}

.week-recipes::after {
  content: '';
  width: 100%;
  height: 2px;
  background-color: rgb(0, 0, 0, 0.4);
}

.week-section:last-of-type .week-recipes::after {
  display: none !important;
}


.category {
  flex: 1;
  min-width: 200px;
}