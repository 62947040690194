.auth {
  max-width: 300px;
  margin: 100px auto;
  min-height: 400px;
}

.auth h1,
.auth h2 {
  font-weight: 600 !important;
  font-family: 'Outfit' !important;
  font-size: 1.35rem;
}

.auth a {
  color: black;
  text-decoration: underline !important; 
}

.auth form {
  margin: 20px 0;
  text-align: start;
  display: flex;
  flex-direction: column;
}
  
.auth label {
  display: block;
  margin-bottom: 10px;
}

.auth input {
  padding: 8px;
  margin-bottom: 15px;
  box-sizing: border-box;
  border-radius: 0 !important;
  width :100%; 
}

.auth button {
  background-color: #000;
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  max-width: 150px;
}
