.progress-bar {
    max-width: 400px;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#steps {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 10%;
    position: relative;
}

.progress-bar  .step {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60px;
    height: 60px;
    background-color: transparent; /* Ensures background is transparent */
    border-radius: 50%;
    color: hsl(226, 15%, 83%);
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    transition: color 0.3s, border-color 0.3s;
}

.progress-bar .step svg {
    width: 100%;
    height: 100%;
}

.progress-bar .step + .step::before {
    content: '';
    width: 100%; /* Adjusted to ensure lines between steps are even */
    height: 3px;
    background-color: hsl(0, 0%, 91%);
    position: absolute;
    top: 50%;
    left: -150%; /* Correctly aligns the line between steps */
    z-index: -1;
}

.progress-bar .step:nth-child(n+2) {
    transform: translateY(0); /* Ensures steps are on the same line */
}

.progress-bar .step:after {
    content: attr(data-desc);
    font-size: 16px; /* Adjusted font size */
    font-weight: 400;
    color: hsl(226, 10%, 55%);
    position: absolute;
    bottom: -35px; /* Adjusted position to match your screenshot */
    text-align: center;
    width: 120px; /* Increased width for better text wrapping */
}


@media (max-width: 996px) {
    .progress-bar {
        width: 300px;
    }

    .progress-bar .step + .step::before {
        width: 80%;
        left: -90%;
    }    
}