.recipe-card {
    width: 320px;
    min-height: 250px;
    background: #fff;
    position: relative;
    margin-bottom: 2rem;
}

.recipe-card h3 {
    padding-top: 0;
    margin-top: 0.75rem;
    min-height: 46px;
    font-size: 1.4rem;
    margin: 1.5rem;
    font-weight: 500 !important;
}

.recipe-card .content {
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 0.5rem;
}

.infos {
    display: flex;
    text-align: center;
    letter-spacing: 1px;
    gap: 2rem;
    margin-bottom: 2rem;
    display: none;
}

.infos div {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
}

.infos div:not(:last-child)::after {
    content: "";
    background: #000;
    width: 1px;
    height: 100%;
    position: absolute;
    left:3rem;
}

.infos span {
    font-weight: 700;
}

.recipe-card .content .link-container {
    position: absolute;
    bottom: 0;
}

.recipe-card .contains-ingredient {
    border-radius: 32px;
    background: #84B74E;
    color: #fff;
    border-radius: 32px;
    width: auto;
    max-width: 200px;
    text-align: center;
}

.recipe-card .content a {
    display: flex;
    align-items: center;  
    position: relative;  
    gap: 0.5rem;
    text-decoration: none;
    color: #000;
    letter-spacing: 0.5px;
}

.recipe-card .content a::after {
    content: ">";
}

.close-btn {
    position: absolute;
    box-shadow:
    rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border-radius: 50%;
    width: 46px;
    height: 46px;
    top: -15px;
    right: -13px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.close-btn:hover {
    background-color: rgb(238, 238, 238);
}