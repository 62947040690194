.subscription-card {
    background-color: #ffffff;
    border-radius: 20px;
    padding: 30px;
    width: 450px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    font-family: 'Arial', sans-serif;
  }
  
  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
  }
  
  .membership-icon i {
    font-size: 30px;
    color: #333;
  }
  
  .current-plan {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666;
  }
  
  .current-plan .dot {
    width: 8px;
    height: 8px;
    background-color: #666;
    border-radius: 50%;
    margin-right: 5px;
  }
  
  .plan-details h3 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .plan-details ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 25px;
  }
  
  .plan-details ul li {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    font-size: 16px;
  }
  
  .plan-details ul li::before {
    content: '✔';
    margin-right: 10px;
    color: #84B74E;
  }
  
  .price {
    font-size: 28px;
    font-weight: bold;
  }
  
  .plan-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
  }
  
  .browse-plans {
    background-color: #8bc34a;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    flex-grow: 1;
    margin-right: 10px;
    text-align: center;
  }
  
  .cancel-subscription {
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    flex-grow: 1;
    text-align: center;
  }
  
  .browse-plans:hover, .cancel-subscription:hover {
    opacity: 0.9;
  }
  