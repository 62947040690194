.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  .not-found h1 {
    font-size: 6rem;
    margin-bottom: 1rem;
  }
  
  .not-found p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .not-found a {
    font-size: 1.25rem;
    color: #007bff;
    text-decoration: none;
  }
  
  .not-found a:hover {
    text-decoration: underline;
  }
  