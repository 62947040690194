.success-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    padding: 2rem;
    font-family: 'Poppins', sans-serif;
    color: #333;
    text-align: center;
    margin: 5rem 1rem;
    border-radius: 81px;
    box-shadow: 3px 3px 10px #00000029;
    min-height: 500px;
}

.success-page h1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: black;
    margin-bottom: 1.5rem;
    font-family: 'Poppins', sans-serif;
}

.success-page p {
    font-size: 1.5rem;
    color: #5f6368;
    margin-bottom: 2rem;
    max-width: 600px;
    line-height: 1.6;
}

.success-page svg.inbox {
    margin: 2rem 0;
    width: 100px;
    height: 100px;
    fill: #84b74e;
}

.success-page button {
    display: flex;
    background-color: #84B74E !important;
    color: #fff;
    border: none !important;
    border-radius: 32px;
    padding: 10px 20px;
    font-weight: 600;
    align-items: center;
    gap: 8px;
}

.button-group {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    margin-bottom: 2.5rem;
}

.button-group button:disabled {
    background-color: #b0bec5;
    cursor: not-allowed;
    box-shadow: none;
}

.dashboard-link {
    font-size: 1.1rem;
    color: black;
    background-color: none;
    text-decoration: underline;
    text-decoration: none;
    font-weight: 600;
    transition: color 0.3s ease;
}

.dashboard-link:hover {
    color: #2e7d32;
}

.error {
    color: #d32f2f;
    font-size: 1rem;
    margin-top: 1rem;
    font-weight: 500;
}

@media (max-width: 768px) {
    .success-page {
        padding: 1rem;
    }

    .success-page h1 {
        font-size: 2rem;
    }

    .success-page p {
        font-size: 1rem;
    }

    .button-group {
        flex-direction: column;
        gap: 1rem;
        /* width: 100%; */
    }

    /* .button-group button {
        width: 100%;
    } */

    .success-page svg.inbox {
        width: 80px;
        height: 80px;
    }
}
