@font-face {
  font-family: 'Recoleta';
  src: url(./assets/fonts/Recoleta-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: 'Recoleta';
  src: url(./assets/fonts/Recoleta-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: 'Recoleta';
  src: url(./assets/fonts/Recoleta-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: 'Recoleta';
  src: url(./assets/fonts/Recoleta-SemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: 'Recoleta';
  src: url(./assets/fonts/Recoleta-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: 'Scto Grotesk A';
  src: url(./assets/fonts/Scto-Grotesk-A.ttf);
  font-weight: 700;
}

@font-face {
  font-family: 'Basis Grotesque Pro';
  src: url(./assets/fonts/basis-grotesque-pro/BasisGrotesquePro-Bold.ttf);
  font-weight: 700;
}
* {
  /* font-family: "Montserrat", sans-serif; */
  font-family: 'Scto Grotesk A';
  box-sizing: border-box;
  font-weight: 300 !important;
  /* font-family: 'Inter', sans-serif; */
  font-family: 'Inter', sans-serif;
  font-family: Outfit;
  font-family: 'Basis Grotesque Pro';
  font-family: 'Poppins';
}

/* h1,
h2,
h4 {
  font-family: 'Recoleta', sans-serif;
  font-weight: 400;
} */

h3 {
  text-align: left;
  letter-spacing: 0px;
  color: #2B3231;
  opacity: 1;
}

#root,
footer {
  background-color: rgb(251 250 250);
  background-color: #fff;
}

.App {
  position: relative;
  min-height: 100vh;
}

main {
  min-height: calc(100vh - 80px);
}

body {
  margin: 0;
  padding: 0;
}

body.open-popup {
  overflow: hidden;
}

body.open-popup::before {
  content: "";
  width: 100%;
  height: 100%;
  position: fixed;
  top:0;
  left:0;
  bottom: 0;
  z-index:10;
  background: rgba(0,0,0,0.2);
}

.container {
  max-width: 1400px;
  margin: auto;
  display: flex;
  gap: 2rem;
}

.terms,
.privacy-policy {
  max-width: 1200px;
  margin: auto;
}

.terms h2,
.privacy-policy h2 {
  font-weight: 600 !important;
  font-family: 'Outfit' !important;
  font-size: 1.35rem;
}

.container .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

input {
  border: none;
  border-bottom: 1px solid grey;
  transition: border-color 0.3s;
}

input:focus {
  border-color: black;
  outline: none;
}

img {
  animation: imageLoadAnimation 0.5s ease-ing;
}

@keyframes imageLoadAnimation {
  0% { opacity: 0.6; }
  100% { opacity: 1; }
}

.section-heading {
  text-align: center;
}

.section-heading h2 {
  max-width: 1200px;
  margin: 0 auto 0.25rem auto;
  text-align: start;
  letter-spacing: 1.5px;
  font-weight: 300;
  font-size: 24px;
  text-transform: uppercase;
  /* font-family: Outfit !important; */
}

.section-heading p {
  max-width: 1200px;
  letter-spacing: 0.2px;
  font-weight: 300;
  text-align: start;
}

.recipes {
  display: flex;
  max-width: 1100px;
  margin: auto;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
}

.recipes>div {
  width: 100%;
}

.recipes .meals {
  display: flex;
  gap: 1rem;
}

.next-step {
  display: flex;
  justify-content: end;
  position: sticky;
  bottom: 0;
  right: 0;
  background: #faf9f8;
  margin: 0 auto;
  padding: 4px 0;
  width: 100%;
}

.submit {
  display: flex;
  justify-content: end;
  width: 90%;
  margin: auto !important;
}

.next-step a,
.next-step button,
.submit a,
.submit button {
  border-radius: 32px !important;
  background: #84b74e !important;
  color: #fff !important;
  text-decoration: none !important;
  padding: 1rem 2rem !important;
  letter-spacing: 1px;
  width: 90%;
  margin: auto;
}

/* Free trial message */
.free-trial-message {
  background-color: #84B74E;
  background-color: #84B74E;
  color: #fff;
  padding: 0.5em;
  /* font-weight: 600; */
  letter-spacing: 0.01em;
  margin: auto;
  padding-left: 5%;
}

.free-trial-message .message {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  max-width:70%;
}

.free-trial-message a {
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: underline;
}

.free-trial-message a::after {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 50 50' fill='none'%3E%3Cpath d='M32.7058 10.4167H29.7735L42.1484 22.9167H4.16663V25.0001H42.4081L29.7795 37.5001H32.7405L46.2646 24.113L32.7058 10.4167Z' fill='white' stroke='white' strokeWidth='1'%3E%3C/path%3E%3C/svg%3E");
}

.close-button {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 1rem;
  top: 1rem;
  background-color: #EFEFEF;
}

button.primary,
a.primary {
  background: #84B74E !important;
  font-size: 1.2em;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  min-width: 175px;
  margin-top: 1rem;
  border-radius: 32px;
  background: none;
  text-align: center;
  padding: 0;
  letter-spacing: 0.02em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem auto;
  gap: 8px;
  padding: 0.65rem;
  color: #FFF;
  /* font-family: Outfit; */
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-decoration: none;
  max-width: 230px;
}

button.primary:hover,
a.primary:hover {
  opacity: 0.7;
}

button.secondary,
a.secondary {
  border: 1px solid black;
  background: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  min-width: 175px;
  margin-top: 1rem;
  border-radius: 32px;
  background: none;
  text-align: center;
  letter-spacing: 0.02em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  gap: 8px;
  padding: 0.65rem;
  color: #000;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-decoration: none;
  max-width: 200px;
}

button.secondary:hover,
a.secondary:hover {
  background: rgba(0, 0, 0, 0.06);
}

/* TAGS */
.tag.vege {
  max-width: 50px;
  text-align: center;
  font-size: 14px;
}

.tag.vege {
  background: #ec9736;
  color: #fff;
}

.tag.vegan {
  background: #6B8E23;
  color: #fff;
}

@media (max-width: 768px) {
  .section-heading p {
    max-width: 90%;
  }
  
  .recipes {
    justify-content: center;
  }

  button.primary,
  a.primary {
  min-width: auto;
  font-size: 16px;
}
}