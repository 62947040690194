header {
  height: 80px;
  box-shadow: 1px 0px 0px 0px #F4F4F4 inset;
  border-bottom: 1px solid hsl(210, 14%, 89%) !important;
}

header button.primary, a.primary {
  margin: 0;
}

.css-18esm8n {
  padding: 0 !important;
}

.chakra-menu__menu-list a {
  padding: 5px 10px;
  width: 100%;
}

header .container {
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.15rem 1rem;
  height: 80px;
}

header .current-user {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

header .membership-status {
  background: #2B3231;
  color: white;
  border-radius: 32px;
  font-size: 11px;
  padding: 0 8px;
}

header .current-user>div {
  display: flex;
  flex-direction: column;
  margin-right: 8px;
}

header .left span {
  font-family: 'Recoleta' !important;
  font-size: 2rem;
  font-weight: 600 !important;
}

header .right {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

header .right span:first-of-type {
  font-weight: 600 !important;
}

header .right span:nth-of-type(2) {
  color: #6E6D7A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.auth-btns .sign-up {
  padding: 0.3em 1.45em;
  margin-right: 6px;
  background: #84B74E;
  border-radius: 32px;
  color: white;
}

.auth-btns .login {
  padding: 0.4em 1em;
}

header .right div {
  display: flex;
  flex-direction: column;
}

header .right span {
  cursor: pointer;
}

.menu-mobile-icon {
  display: none;
}

.auth-btns {
  display: flex;
}

.auth-btns .primary {
  padding: 0.3em 1.45em;
  background: black;
  border-radius: 32px;
  color: white;
  margin-right: 6px;
}

.auth-btns .secondary {
  padding: 0.4em 1em;
}

@media (max-width: 1200px) {
  header .left span {
    font-size: 1.5rem;
    text-align: center;
  }

  .menu-mobile-icon {
    display: block;
  }

  .right .sign-up,
  .right .login {
    display: none;
  }
}