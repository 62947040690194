.food-preferences .form-group {
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    text-align: center;
    margin-bottom: 20px;
}

.food-preferences {
    max-width: 1200px;
    margin: auto;
    padding: 40px 20px; /* Add padding to space out the content */
}

.user-dashboard form section {
    width: 100%;
    position: relative;
    min-height: 400px;
    box-sizing: border-box;
    -webkit-transform: scale(0.9); /* Scale down slightly */
    transform: scale(0.9); /* Scale down slightly */
    opacity: 0.5; /* Set lower opacity */
    transition: all 0.2s ease-in-out; /* Smooth transition for all properties */
    border: 1px solid transparent; /* Transparent border to maintain layout consistency */
}

.user-dashboard form section.active {
    -webkit-transform: scale(1); /* Scale to full size */
    transform: scale(1); /* Scale to full size */
    opacity: 1; /* Full opacity */
    z-index: 1; /* Bring to front */
}


.user-dashboard button.active,
.user-dashboard .current-week-recipes a,
.user-dashboard button.no-specific-ingredient {
    background-color: #84B74E !important;
    color: #fff; /* Ensure the text color contrasts well with the button background */
    border: none !important;
    border-radius: 32px; /* Rounded corners for a more modern look */
    padding: 10px 20px; /* Add padding for button content */
    font-weight: 600; /* Make the text bold */
}

.food-preferences .diet button {
    max-width: none;
    text-align: center;
}

.food-preferences .form-container {
    margin: auto;
    max-width: 500px; /* Increase the max-width for a more balanced look */
}

.food-preferences form label {
    margin-bottom: 8px;
    display: block;
}

.food-preferences form h3 {
    font-size: 1.35rem;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    margin-bottom: 20px; /* Space out the heading from the content */
}

.food-preferences h2 {
    width: 100%;
    margin: 30px auto;
    text-align: center;
}

.form-label {
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
}

.radio-group {
    display: flex;
    justify-content: start;
    gap: 3rem;
}

.radio-label {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-right: 30px;
    max-width: 30px;
}

.food-preferences .num-recipes button,
.food-preferences .servings button {
    background-color: #F4F4F4;
    border: none !important;
    border-radius: 40px;
    margin: 0 4px;
    width: 46px !important;
    height: 46px !important;
    text-align: center;
    line-height: 23px;
    font-size: 1.2em;
    font-weight: bold; /* Add weight to make the buttons stand out */
}

.ingredients-to-include,
.ingredients-to-avoid {
    display: flex;
    flex-direction: column;
}

.ingredients-to-include>div>div,
.ingredients-to-avoid>div>div {
    display: flex;
    align-items: center;
    gap: 0 !important;
    margin-top: 8px; /* Space out the ingredient chips */
    flex-wrap: wrap;
}

.ingredients-container,
.ingredients-inputs {
    display: flex;
    align-items: center;
    gap: 10px; /* Space out the ingredient input field */
}

.ingredients.ingredient,
.ingredients .ingredient {
    border-radius: 32px;
    background-color: #84B74E !important;
    color: white;
    padding: 0 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0.5em;
    padding: 0.5em 1em;
}

.ingredient button {
    display: flex;
    justify-content: space-between;
    margin: 0 !important;
    padding: 0 !important; 
    gap: 8px;
    align-items: center;
}


/* General form group styling */
.food-preferences .form-group {
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    text-align: center;
    margin-bottom: 20px;
}

/* Overall layout adjustments */
.food-preferences {
    max-width: 1200px;
    margin: auto;
    padding: 40px 20px;
}

/* Ingredients sections styling */
.ingredients-to-include,
.ingredients-to-avoid {
    padding: 20px;
    text-align: center; /* Center content */
    margin-bottom: 20px; /* Space between sections */
}

.ingredients-inputs {
    display: flex;
    flex-direction: column;
    gap: 16px; /* Space between input and button */
    align-items: center;
}

.ingredients-inputs input {
    background: #e0e0e0; /* Light gray background for input */
    height: 60px; /* Taller input */
    border: none;
    border-radius: 32px; /* Rounded input */
    padding: 0 20px;
    font-size: 1.1rem; /* Larger font size */
    width: 100%; /* Full width input */
    max-width: 500px; /* Constrain width */
    text-align: center; /* Centered text */
}

.ingredients-inputs button {
    background-color: #84B74E; /* Green background */
    color: #fff; /* White text */
    border: none;
    border-radius: 32px; /* Rounded button */
    padding: 15px 40px; /* Large padding for substantial feel */
    font-size: 1.1rem; /* Larger font size */
    font-weight: 600;
    cursor: pointer;
    width: 100%; /* Full width button */
    max-width: 500px; /* Constrain width */
    transition: background-color 0.3s ease;
}

.ingredients-inputs button:hover {
    background-color: #6e9d3d; /* Slightly darker green on hover */
}

/* Container adjustments for better centering */
.food-preferences .form-container {
    margin: auto;
    max-width: 600px; /* Adjust max-width for better aesthetics */
    text-align: center;
}

@media (max-width: 768px) {
    .ingredients-inputs input,
    .ingredients-inputs button {
        max-width: 100%; /* Full width on smaller screens */
    }
}

/* General form group styling */
.food-preferences .form-group {
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    text-align: center;
    margin-bottom: 20px;
}

/* Ingredients sections styling */
.ingredients-to-include,
.ingredients-to-avoid {
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
}

.ingredients-inputs {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
}

.input-group {
    display: flex;
    gap: 10px; /* Space between input and Add button */
    width: 100%;
    max-width: 500px;
}

.input-group input {
    background: #e0e0e0;
    height: 60px;
    border: none;
    border-radius: 32px;
    padding: 0 20px;
    font-size: 1.1rem;
    flex: 1; /* Take up all available space */
    text-align: center;
}

.input-group .add-button {
    background-color: #84B74E;
    color: #fff;
    border: none;
    border-radius: 32px;
    padding: 15px 20px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
}

.input-group .add-button:hover {
    background-color: #6e9d3d;
}

.ingredients-inputs button.no-specific-ingredient {
    background-color: #84B74E;
    color: #fff;
    border: none;
    border-radius: 32px;
    padding: 15px 40px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    max-width: 500px;
}

.ingredients-inputs button.no-specific-ingredient:hover {
    background-color: #6e9d3d;
}

@media (max-width: 768px) {
    .input-group {
        flex-direction: column;
        align-items: center;
    }

    .input-group input,
    .input-group .add-button,
    .ingredients-inputs button.no-specific-ingredient {
        max-width: 100%;
    }
}



.ingredients-to-include input,
.ingredients-to-avoid input {
    background: #F4F4F4;
    height: 60px;
    border: none;
    border-radius: 32px;
    padding: 0 16px;
    width: 100%;
}
/* 
.dashboard .ingredients-to-include button,
.dashboard .ingredients-to-avoid button {
    background: none !important;
    box-shadow: none !important;
} */

.ingredients-to-include .ingredient button,
.ingredients-to-avoid .ingredient button {
    color: white;
    box-shadow: none !important;
}

.user-dashboard form {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    max-width: 800px;
    margin: 0.5em auto;
    padding: 1em;
}

.user-dashboard button,
.user-dashboard input[type="number"] {
    margin: 0.5em;
    padding: 0.5em 0.5em;
    font-size: 1em;
    line-height: 1.5;
    cursor: pointer;
}

.user-dashboard button:disabled,
.user-dashboard input:disabled {
    opacity: 0.3;
    cursor: auto;
}

.user-dashboard button[type="submit"] {
    background-color: black;
    color: white;
    border: none;
    padding: 0.5em 1.5em;
    font-size: 1.2em;
    border-radius: 32px;
}

.user-dashboard button[type="submit"]:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.meal-preferences {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}

.meal-item {
    flex: 1 1 32%;
    background: #e0e0e0;
    border-radius: 35px;
    padding: 10px 10px 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 65px;
    max-width: 320px;
}

.meal-item label {
    flex: 1;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0 !important;
    padding-left: 16px;
}

.counter {
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 27px;
    padding: 0 0.5em !important;
    margin: 0 !important;
}

.counter button {
    background: #fff !important;
    border: none !important;
}

.counter span {
    font-size: 18px;
    font-weight: 600;
}

.input-with-button {
    display: flex;
    align-items: center;
  }
  
.input-with-button input {
    flex: 1;
    padding-right: 50px;
}

.input-with-button .add-button {
    margin-left: -50px;
    padding: 5px 10px;
    cursor: pointer;
    border: none;
    background-color: #84B74E !important;
    color: white;
    border-radius: 4px;
    height: 60px;
    border-radius: 32px;
    min-width: 100px;
}

@media (max-width: 768px) {
    .food-preferences .form-container {
        max-width: 100%;
        padding: 20px;
    }

    .meal-preferences {
        flex-direction: column;
        align-items: center;
    }

    .meal-item {
        width: 100%;
        max-width: none;
    }

    .food-preferences {
        padding: 0 !important;
    }
    
}
