.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 500px;
  max-height: 90vh; /* Add this to limit the height of the modal */
  overflow-y: auto; /* Add scroll if content exceeds modal height */
  position: relative;
  box-sizing: border-box;
}

.modal-close-button {
  background: none;
  color: #999;
  border: none;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.modal-close-button:hover,
.modal-close-button:focus {
  color: #555;
}

.payment-type-toggle {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
}

.payment-type-toggle button {
  flex: 1;
  padding: 12px 20px;
  margin: 0 10px;
  background-color: #e5e5e5;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  color: #333;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.payment-type-toggle button.active {
  background-color: #84B74E;
  color: white;
}

.purchase-details {
  margin-bottom: 20px;
  text-align: center;
}

.purchase-details h3 {
  margin-bottom: 10px;
  font-size: 1.4em;
  color: #333;
  font-weight: 600;
}

.purchase-details p {
  font-size: 1em;
  color: #666;
  text-align: start;
}

.price-display {
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-top: 10px;
  padding: 15px;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.price-display .currency {
  font-size: 1.2em;
  color: #888;
  margin-right: 5px;
}

.price-display .price {
  font-size: 2.2em;
  color: #84B74E;
  font-weight: bold;
}

.pay-button {
  display: block;
  width: 100%;
  padding: 15px;
  margin-top: 30px;
  background-color: #84B74E;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 1.1em;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pay-button:hover {
  background-color: #84B74E;
}

#payment-message {
  margin-top: 20px;
  color: #d9534f;
  font-weight: bold;
  text-align: center;
}

.spinner {
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top: 2px solid #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 0.8s linear infinite;
  margin: 0 auto;
  display: block;
}

#button-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 600px) {
  .modal {
    padding: 20px; /* Reduce padding for smaller screens */
  }

  .payment-type-toggle button {
    padding: 10px 15px; /* Adjust button padding */
    font-size: 0.9em; /* Reduce font size */
  }

  .purchase-details h3 {
    font-size: 1.2em; /* Adjust heading font size */
  }

  .price-display .price {
    font-size: 1.8em; /* Adjust price font size */
  }

  .pay-button {
    padding: 12px; /* Adjust button padding */
    font-size: 1em; /* Adjust button font size */
  }
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
}

.modal-close-button {
  background: none;
  border: none;
  font-size: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  padding: 20px;
}

.form {
  display: flex;
  flex-direction: column;
}

.input {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button {
  padding: 10px;
  background-color: #84B74E ;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.button:disabled {
  background-color: #ccc;
}

.payment-type-toggle {
  display: flex;
  justify-content: space-around
}

.modal-content h2 {
 font-family: 'Poppins';
}

.link-button {
  text-decoration: underline;
}