.user-dashboard {
  width: 100%;
}

.user-dashboard a {
  text-decoration: none;
  color: #000;
}

.dashboard .tab-content {
  padding: 2rem;
  background: #faf9f8;
  min-height: 100vh;
  border: 0.3px solid #e5e7eb;
}

.dashboard .tab-content .auth form{
  gap: 1rem !important;
  padding: 0;
}

.user-dashboard form {
  flex-direction: column;
  align-items: start;
}

.user-dashboard .top {
  max-width: 1200px;
  padding: 0 8px;
  margin: 0 auto;
}

.user-dashboard .current-week.top {
  border-bottom: 0.5px solid rgb(224, 224, 224);
  padding-bottom: 8px;
}

.user-dashboard .top h2 {
  font-weight: 600 !important;
  font-family: 'Outfit' !important;
  font-size: 1.35rem;
}

.user-dashboard button[type="submit"] {
  background: #000;
  font-size: 1em;
}

.user-dashboard button[type="submit"]:hover {
  background: #000;
}

.dashboard .tab-content .auth {
  margin: 1.5rem auto;
}

.sidebar-nav {
  position: fixed;
  left: 0;
  top: 0;
  overflow: scroll;
  height: 100%;
  margin-top: 107px;
  background: #F1F2F7;
}

.sidebar-nav a {
 padding: 10px;
 font-size: 14px;
 letter-spacing: 0.01em;
}

.sidebar-nav a.active {
  font-weight: 700;
}

.tab-content .collapse-btn {
  left: 0;
  top: 0;
  /* background-color: #000; */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 10;
}

.tab-content .collapse-btn svg {
  transform: rotate(180deg);
}

.sidenav-bar-open .collapse-btn svg {
  transform: rotate(0deg) !important;
}

button.collapse-btn {
  display: none !important;
}

.sidebar-nav {
  transform: translate(-100%);
  transition: all 0.3s ease;
}

.sidenav-bar-open .tab-content {
  /* margin-left: 100px; */
  transition: all 0.3s ease;
  padding: 2.5rem;
  /* position: relative; */
}

.sidenav-bar-open .sidebar-nav {
  transform: translate(0);
}

/* .tab-content .collapse-btn {
  right: 0;
  left: auto;
} */

.sidebar-section {
  position: relative;
  padding: 1rem;
}

.sidebar-section span {
  padding: 10px 15px;
}

.sidebar-section:not(:last-of-type)::after {
  content: "";
  width: 100%;
  background: #000;
  height: 0.5px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.dashboard .tabs {
  display: flex;
  flex-direction: column;
  min-width: 300px;
}

.dashboard .counter {
  background: white;
  border: none !important;
  color: #000;
  padding: 0 15px;
  height: 50px;
  cursor: pointer;
  outline: none;
  text-align: start;
  margin: 0;
  transition: all 0.3s ease;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px !important;
  border-radius: none !important;
}

.user-dashboard button.active {
  font-weight: 500;
  background-color: #84B74E;
  border: none !important;
  color: white;
}

.user-dashboard .current-week-recipes,
.user-dashboard .previous-weeks,
.user-dashboard .my-account,
.user-dashboard .my-plan {
  max-width: 1200px;
  margin: 8px auto;
  padding: 1rem 2rem;
  /* box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px; */
}

.user-dashboard .current-week-recipes h2 {
  font-family: 'DM Sans';
}

.user-dashboard .current-week-recipes ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding-left: 0;
  max-width: 1200px;
  margin: auto;
  margin-top: 8px;
}

.user-dashboard .current-week-recipes ul li {
  position :relative;
  background: #fff;
  /* box-shadow: 0 0 8px #17171a0d, 0 2px 8px #17171a14; */
}

.user-dashboard .current-week-recipes ul li span {
  font-weight: 400;
  letter-spacing:0.02em;
  font-size: 18px;
}

.user-dashboard .current-week-recipes .tag {
  width: 55px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1.2px;
  margin-top: 4px;
}

.user-dashboard .current-week-recipes .recipe-text {
  display: flex;
  flex-direction: column;
}

.current-week-recipes .top {
  border-bottom: 1px solig grey;
}

.user-dashboard .section-header {
  display: flex;
  align-items: end;
  justify-content: space-between;
  max-width: 1200px;
  margin: auto;
  font-size: 14px;
}

.user-dashboard .section-header div:first-of-type span:first-of-type {
  font-weight: 700 !important;
}

.user-dashboard .section-header .recipe-count {
  font-weight: 400 !important;
}

.user-dashboard .section-header .recipe-count span {
  font-weight: 700;
}

.user-dashboard span:first-of-type {
  font-weight: 600;
}

.MuiBox-root {
  max-width: 1200px;
  margin: auto;
  margin-top: 1rem;
}

.css-13xfq8m-MuiTabPanel-root {
  padding: 0 !important;
}

.MuiBox-root button span,
.MuiBox-root button  {
 border: none;
 box-shadow: none !important;
 transition: none !important;
 text-transform: none;
 font-family: 'Scto Grotesk A';
 padding-left: 2px;
 margin-right: 10px;
}

.MuiBox-root button:active span,
.MuiBox-root button:active  {
 border: none;
 box-shadow: none !important;
 transition: none !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected,
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected  span {
  color: black !important;
  background: none !important;
  animation: none;
  transition: none !important;
}

.css-1q2h7u5.Mui-selected {
  color: black !important;
}

.MuiTabs-indicator {
  background-color: black !important;
}
.css-1aquho2-MuiTabs-indicator {
  background-color: black !important;
}

/* .user-dashboard .current-week-recipes ul img {
  border-radius: 8px 0 0 8px;
} */

.user-dashboard .current-week-recipes ul a {
  display: flex;
  align-items: center;
  gap: 2rem;
}


.user-dashboard .current-week-recipes ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
 
.user-dashboard .current-week-recipes ul li>div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.user-dashboard .current-week-recipes a {
  text-align: center;
}

.regenerate {
  margin-right: 0 !important;
  display: flex;
  border: 2px solid #C4C4C4;
  border-radius: 16px;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.regenerate svg {
  width: 24px;
  height: 24px;
}

.regenerate span {
  color: #C4C4C4;
  font-weight: 500 !important;
}

.handle-recipes {
  display: flex;
  align-items: center;
  justify-content: center;
}

.handle-recipes>div button {
  box-shadow: none !important;
}

/* Past weeks */
.previous-weeks {
  max-width: 800px;
  margin: auto;
}

.user-dashboard .previous-weeks .pagination {
  text-align: center;
}

/* .user-dashboard .previous-weeks h2 {
  text-align: center;
} */

.user-dashboard .previous-weeks h2,
.user-dashboard .previous-weeks h3,
.user-dashboard .my-account h2 {
  font-family: 'Scto Grotesk A';
}

.user-dashboard .previous-weeks .pagination div {
  display: flex;
  justify-content: center;
}

.user-dashboard .previous-weeks .pagination p {
  text-align: center;
}


.user-dashboard .previous-weeks .pagination button {
  text-decoration: underline;
  border: none !important;
}

.previous-weeks ul {
  display: flex;
  list-style: none;
  padding-left: 0;
  margin-bottom: 2rem;
}

.previous-weeks .week-info {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.previous-weeks img {
  border-radius: 8px;
  width: 200px;
  height: 200px;
  margin-bottom: 8px;
}

.previous-weeks .week-info p {
  text-decoration: underline;
  cursor: pointer;
}

.previous-weeks span:first-of-type {
  font-weight: 400;
  font-family: 'Recoleta';
  max-width: 95%;
  padding-bottom: 4px;
}

.previous-weeks ul {
  display: flex;
  gap: 1rem;
}

.previous-weeks ul li a {
  display: flex;
  flex-direction: column;
  list-style: none;
  max-width: 200px;
}

/* Pop up grocery list previous week */
.grocery-list-popup {
  position: fixed;
  background: #fff;
  top: 4rem;
  max-width: 900px;
  width: 90%;
  height: 80%;
  overflow-y: scroll;
  padding: 1.5rem;
  z-index: 15;
  border-radius: 16px;
  left: 50%;
  transform: translate(-50%);
}

.grocery-list-popup h3 {
  font-weight: 700 !important;
  font-family: 'Recoleta', sans-serif !important;
}

.grocery-list-popup ul {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1200px) {
  .dashboard .tab-content {
    padding: 0.5rem;
  }

  .user-dashboard form>div {
    padding: 1rem 0;
    margin: 0;
  }

  .user-dashboard form>div.ingredients-to-include:first-of-type, .user-dashboard form>div.ingredients-to-avoid:first-of-type{
    width: 100%;
  }

  .user-dashboard .food-preferences form {
    flex-direction: column;
  }

  .user-dashboard .current-week-recipes, .user-dashboard .previous-weeks, .user-dashboard .my-account, .user-dashboard .my-plan {
    padding: 0.5rem;
  }

  .previous-weeks ul {
    flex-wrap: wrap;
  }

  .previous-weeks .week-info {
    flex-direction: column;
    align-items: start;
    margin-bottom: 1rem;
  }
   
  .user-dashboard {
    padding-left: 0;
  }

  .sidebar-container {
    z-index:10;
  }

  .user-dashboard .current-week-recipes ul li>div {
    flex-direction: column;
  }

  .user-dashboard .current-week-recipes ul li {
    /* flex-direction: column; */
    /* align-items: start; */
  }
}